import initPreloader from './modules/preolader';
import initNavigation from './modules/navigation';
import initSwiper from './modules/swiper';
import initAccordion from './modules/accordion';
import initRunningLine from './modules/running-line';
import initFilterScroll from './modules/filter-scroll';
import initTestimonialsSwiper from './modules/testimonials-swiper';
import initNavigationArticle from './modules/navigation-article';

import '../scss/styles.scss';
import initSwiperOffers from './modules/swipper-offers';
import initSwiperTestimonials from './modules/swipper-testimonials';
import initAccordionFaq from './modules/accordion-faq';
import initSwiperInformationCards from './modules/information-cards-swiper';
import { rotateButton } from './helpers';

document.addEventListener('DOMContentLoaded', () => {
  initPreloader();
  initSwiper();
  initNavigation();
  initAccordion();
  initRunningLine();
  initFilterScroll();
  initTestimonialsSwiper();
  initNavigationArticle();
  initSwiperOffers();
  initSwiperTestimonials();
  initAccordionFaq();
  initSwiperInformationCards();
  rotateButton('.testimonials_slider__navigation');
  rotateButton('.information_cards_slider__navigation');
});
