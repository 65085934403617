export const rotateButton = (containerButtons) => {
  const $ = (el) => document.querySelector(el);
  let initRotationPrev = 0;
  let initRotationNext = 0;

  const prev = $(`${containerButtons}-button-prev `);
  const next = $(`${containerButtons}-button-next `);

  prev.addEventListener('click', () => {
    initRotationPrev -= 90;
    prev.style.transform = `rotate(${initRotationPrev}deg)`;
  });
  next.addEventListener('click', () => {
    initRotationNext += 90;
    next.style.transform = `rotate(${initRotationNext}deg)`;
  });
};
