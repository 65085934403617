import Swiper from 'swiper';
import { Navigation } from 'swiper';

export default function initSwiperTestimonials() {
  const $ = (el) => document.querySelector(el);

  const swiperElement = $('.testimonials_slider');
  if (!swiperElement) return;

  const slider = $('.testimonials_slider_swiper');
  new Swiper(slider, {
    modules: [Navigation],
    slidesPerView: 2.5,
    spaceBetween: 30,
    loop: true,
    slidesOffsetBefore: 0,
    centeredSlides: true,
    effect:'slide',
    initialSlide: 0,
    breakpoints: {
      0: {
        slidesPerView: 1.2,
        spaceBetween: 20
      },
      767: {
        slidesPerView: 1.75,
        spaceBetween: 96
      },
      992: {
        slidesPerView: 2.5,
        spaceBetween: 30
      }
    },

    navigation: {
      nextEl: '.testimonials_slider__navigation-button-next',
      prevEl: '.testimonials_slider__navigation-button-prev'
    }
  });
}
