export default function initAccordionFaq() {
  const acc = document.querySelectorAll('.accordion_faq__item');

  acc.forEach((accItem) => {
    accItem.addEventListener('click', function () {
      const isActive = this.classList.contains('active');

      acc.forEach((item) => {
        item.classList.remove('active');
        const panel = item.querySelector('.accordion_faq__item-panel');
        panel.style.maxHeight = null;
        panel.classList.remove('active');
        const head = item.querySelector('.accordion_faq__head');
        head.classList.remove('active');
      });

      if (!isActive) {
        this.classList.add('active');
        const head = this.querySelector('.accordion_faq__head');
        const panel = this.querySelector('.accordion_faq__item-panel');
        panel.classList.add('active');
        head.classList.add('active');
        panel.style.maxHeight = panel.scrollHeight + 25 + 'px';
      }
    });
  });
}
