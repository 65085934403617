import Swiper from 'swiper';
import { Navigation } from 'swiper';

export default function initSwiperOffers() {
  const $ = (el) => document.querySelector(el);

  const swiperElement = $('.slider_offers');
  if (!swiperElement) return;

  const slider = $('.slider_offers__swiper-image');
  new Swiper(slider, {
    modules: [Navigation],
    loop: true,
    allowTouchMove: false,
    navigation: {
      nextEl: $('.slider_offers__navigation-button-next'),
      prevEl: $('.slider_offers__navigation-button-prev')
    }
  });

  const sliderText = $('.slider_offers__swiper-text');
  new Swiper(sliderText, {
    modules: [Navigation],
    allowTouchMove: false,
    loop: true,
    navigation: {
      nextEl: $('.slider_offers__navigation-button-next'),
      prevEl: $('.slider_offers__navigation-button-prev')
    }
  });
}
