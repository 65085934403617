import Swiper from 'swiper';
import { Navigation } from 'swiper';

export default function initSwiperInformationCards() {
  const $ = (el) => document.querySelector(el);

  const slider = $('.information_cards_slider_swiper');
  new Swiper(slider, {
    modules: [Navigation],
    slidesPerView: 2,
    spaceBetween: 30,
    loop: true,
    breakpoints: {
      0: {
        slidesPerView: 1.08,
        spaceBetween: 20,
        height: 300
      },
      767: {
        slidesPerView: 1.5,
        spaceBetween: 30
      },
      1024: {
        slidesPerView: 2,
        spaceBetween: 20
      }
    },

    navigation: {
      nextEl: '.information_cards_slider__navigation-button-next',
      prevEl: '.information_cards_slider__navigation-button-prev'
    }
  });
}
